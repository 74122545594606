import { getDealerProfile } from '@bbx/ad-in/organisation/api/dealerProfileApiClient'
import { DealerProfileContainer } from '@bbx/ad-in/organisation/components/DealerProfileContainer'
import { DealerTabs } from '@bbx/ad-in/organisation/components/DealerTabs'
import { DealerProfile } from '@bbx/ad-in/organisation/types/DealerProfile'
import { sanitizeAdDescription } from '@bbx/common/lib/xss'
import { getAlertSearchResult, getSeoSearchResult } from '@bbx/search-journey/common/api/searchApiClient'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { DesktopBreadcrumbs } from '@bbx/search-journey/sub-domains/search/components/common/result-list/DesktopBreadcrumbs/DesktopBreadcrumbs'
import { RealEstateResultListContainer } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/RealEstateResultListContainer'
import { ErrorPage } from '@wh/common/chapter/components/ErrorPage/ErrorPage'
import type { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { SkyscraperLayout } from '@wh/common/chapter/components/Layouts/SkyscraperLayout'
import { staticRelativeCanonicals } from '@wh/common/chapter/hocs/staticRelativeCanonicals'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { concatPathWithQueryParams, getFirstString } from '@wh/common/chapter/lib/urlHelpers'
import { ExpressNextContextWithUserData } from '@wh/common/chapter/types/nextJS'
import { NextPage } from 'next'
import React, { Fragment } from 'react'

export type DealerProfileProps =
    | {
          searchResult: SearchResult
          dealerProfile: DealerProfile
      }
    | {
          is404: true
      }

export const DealerProfilePage: NextPage<DealerProfileProps> & { Layout: typeof Layout } = (props) => {
    if ('is404' in props) {
        return <ErrorPage statusCode={404} />
    } else {
        const companyDescription =
            props.dealerProfile.organisation.description && sanitizeAdDescription(props.dealerProfile.organisation.description)
        return (
            <Fragment>
                <DesktopBreadcrumbs breadcrumbs={breadcrumbs} />
                <DealerProfileContainer organisation={props.dealerProfile.organisation} />
                <DealerTabs
                    dealerProfile={props.dealerProfile}
                    companyDescription={companyDescription}
                    companyPhoto={props.dealerProfile.organisation.officePhotoUrls?.large}
                    resultListLabel="Angebot"
                    resultListContainer={
                        <RealEstateResultListContainer
                            searchResult={props.searchResult}
                            pageType="dealer-profile"
                            pageViewEvent="dealer_profile"
                        />
                    }
                />
            </Fragment>
        )
    }
}

export const dealerProfileGetInitialProps =
    () =>
    async ({ query, req, res }: ExpressNextContextWithUserData): Promise<DealerProfileProps> => {
        const { orgId: rawOrgId, seopath, alertId, ...remainingQuery } = query
        const path = ['/immobilien/immobilienmakler', ...((seopath ?? []) as string[])].join('/')
        const searchAgentId = getFirstString(alertId)

        const dealerProfileOrgId = getFirstString(rawOrgId)

        if (!dealerProfileOrgId) {
            if (res) {
                res.statusCode = 404
            }
            return { is404: true }
        }

        try {
            const concatenatedPath = concatPathWithQueryParams(path, { ...remainingQuery, orgId: dealerProfileOrgId })
            const searchResultPromise = searchAgentId ? getAlertSearchResult(query, req) : getSeoSearchResult(concatenatedPath, req)
            const dealerProfilePromise = getDealerProfile(dealerProfileOrgId, req)

            const [searchResult, dealerProfile] = await Promise.all([searchResultPromise, dealerProfilePromise])

            return { searchResult, dealerProfile }
        } catch (error) {
            const apiError = wrapInApiErrorIfNecessary(error)

            if (apiError.statusCode && apiError.statusCode >= 400 && apiError.statusCode <= 499) {
                if (res) {
                    res.statusCode = 404
                }
                return { is404: true }
            }

            throw apiError
        }
    }

const breadcrumbs = [
    { displayName: 'Startseite', seoUrl: staticRelativeCanonicals.Home },
    { displayName: 'Immobilien', seoUrl: staticRelativeCanonicals.EstateHome },
    { displayName: 'Maklerübersicht', seoUrl: staticRelativeCanonicals.EstateDealerResultList },
    { displayName: 'Maklerprofil', seoUrl: '' },
]

DealerProfilePage.getInitialProps = dealerProfileGetInitialProps()
DealerProfilePage.Layout = SkyscraperLayout

export default DealerProfilePage
